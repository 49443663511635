.root {
  position: fixed;
  bottom: spacing(2);
  left: spacing(2);
  z-index: var(--z-modal);
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: var(--content-min-width);
  max-width: 754px;
  padding: spacing(3);
  border-radius: 4px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.24);

  & > * + * {
    margin-top: spacing(3);
  }

  @media (max-width: 774px) {
    bottom: 0;
    left: 0;
    padding: var(--modalMargin);
  }
}

.form {
  display: flex;
  justify-content: space-between;

  & > * + * {
    margin-left: spacing(2);
  }

  @media ($tablet-portrait-down) {
    flex-direction: column;
    margin-left: 0;

    & > * + * {
      margin-top: spacing(3);
      margin-left: 0;
    }
  }
}

.fields {
  display: flex;
  align-items: center;
  /* flex-direction: column; */

  & > * + * {
    margin-left: spacing(3);
  }
}

.checkbox {
  font-size: 12px;
}

.button {
  flex-shrink: 0;
  width: min-content;
  height: 40px;
  padding: 0 24px !important;
  font-size: 12px;
  white-space: nowrap;

  @media ($tablet-portrait-down) {
    flex-shrink: 1;
    width: 100%;
  }
}

.content {
  font-size: 12px;

  h1,
  h2,
  h3 {
    font-size: 14px;
  }

  a {
    text-decoration: none;
  }
}

.actions {
  display: flex;
  flex-shrink: 0;

  & > * + * {
    margin-left: spacing(3);
  }

  @media ($small-phone-down) {
    flex-direction: column;

    & > * + * {
      margin-top: spacing(1);
      margin-left: 0;
    }
  }
}
