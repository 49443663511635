.root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: var(--z-top);
  width: 328px;
  background-color: var(--powder-pink);
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);
  transition: transform 250ms ease;
  transform: translate(120%, 0);
}

.open {
  display: block;
  transform: translate(0%, 0);
}

.languages {
  margin-top: spacing(4);

  & > * {
    padding: spacing(4, 2);
    border-bottom: 1px solid var(--lightgrey);

    &:first-child {
      padding: spacing(0, 2);
      border-bottom: 0;
    }
  }
}

.close {
  margin: spacing(4, 2);
}
