.root {
  position: relative;
  height: 3rem;
  padding: 0.75rem;
  margin: 0;
  font-size: 1rem;
  line-height: 1rem;
  color: inherit;
  background-color: transparent;
  border: 2px solid var(--black);
  border-radius: 0;

  &:disabled {
    background-color: var(--greyish);
    border-color: transparent !important;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
}

.multiline {
  height: auto;
  font-family: var(--fontFamily-sofia: sofia-pro, sans-serif);
  resize: none;
}

.inputRootNode {
  position: relative;
}

.label {
  display: block;
  margin-bottom: spacing(1);
}

.error {
  border-color: var(--error-red);
}

.block {
  display: block;
  width: 100%;
}

.input[type='checkbox'] {
  position: absolute;
  visibility: hidden;
}

.fauxCheckbox {
  position: relative;
  display: inline-block;
  width: spacing(3);
  height: spacing(3);
  color: white;
  background-color: transparent;
  border: 2px solid var(--grey-solo);
  border-radius: spacing(0.25);

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  :checked ~ & {
    background-color: var(--soft-green);
    border-color: var(--soft-green);
  }
}

.errorMsg {
  padding-top: 5px;
  font-size: 0.75rem;
  color: var(--error-red);
}

.empty {
  &[type='time'] {
    color: var(--lightgrey) !important;
  }
}
