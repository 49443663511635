.root {
  width: 100%;
  min-width: var(--content-min-width);
  max-width: var(--content-max-width);
  padding: 0 var(--sideMargin);
  margin: 0 auto;

  @media ($desktop-up) {
    max-width: var(--content-max-width-desktop);
  }

  @media ($small-phone-down) {
    padding: 0 15px;
  }

  hr {
    margin-right: 0;
    margin-left: 0;
  }
}

.desktop {
  @media ($desktop-up) {
    max-width: var(--content-max-width-desktop-large);
    padding: 0 var(--sideMargin);
  }
}

.fullHeight {
  flex-grow: 1;
  height: 100%;
}

.noCollapse {
  max-width: var(--content-max-width-desktop);

  &.desktop {
    max-width: var(--content-max-width-desktop-large) !important;
    padding: 0 var(--sideMargin) !important;
  }
}
