.root {
  display: none;
  line-height: 1.25em;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.action {
  width: 40px;
}

.close {
  padding: 15px !important;

  svg {
    right: 5px !important;
  }
}

.floating {
  position: fixed;
  top: var(--sideMargin);
  right: var(--sideMargin);
  left: var(--sideMargin);
  z-index: 9999;
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: translate(0, -150%);
}

.in {
  display: block;
  transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateY(0%);
}
