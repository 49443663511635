.root {
  width: calc(100% - var(--modalMargin));
  height: calc(100% - var(--modalMargin));

  @media ($phone-up) {
    width: auto;
    /* max-width: calc(100% - var(--modalMargin-desktop)); */
    max-width: 680px;
    height: auto;
    max-height: calc(100% - var(--modalMargin-desktop));
  }
}

.title {
  display: flex;
  align-items: center;
  padding: 12px var(--sideMargin);
  font-size: 16px;
  font-weight: var(--bold);
  white-space: pre;
}

.inner {
  display: flex;
  flex-direction: column;
  padding-top: var(--contextMenuHeight);
  overflow-y: auto;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: var(--sideMargin);
}

.slim {
  padding: calc(var(--sideMargin / 2));
}

.admin {
  width: 360px;
  height: auto !important;
  overflow: visible !important;

  & > * {
    overflow: visible !important;
  }
}

.nipt {
  position: absolute;
  top: 74px;
  width: 360px;
  height: auto !important;
  padding-bottom: 80px;
  overflow: visible !important;

  & > * {
    overflow: visible !important;
  }

  @media ($desktop-up) {
    top: 100px;
  }
}

/* .full {
  height: 100%;
} */

.top {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: var(--contextMenuHeight);
  color: var(--black);

  &.success {
    color: var(--white);
    background-color: var(--soft-green);
  }

  &.error {
    color: var(--white);
    background-color: var(--error-red);
  }

  &.warning {
    color: var(--white);
    background-color: var(--warning-yellow);
  }

  &.info {
    background-color: var(--powder-pink);
  }
}

.swoosh {
  position: absolute;
  top: 0;
  width: 100%;
}

.button {
  position: sticky;
  top: 0;
  filter: invert(1) grayscale(1) contrast(9);
}

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: var(--content-max-width);
  height: calc(100% - var(--contextMenuHeight));
  padding: 0 var(--sideMargin);

  @media ($phone-up) {
    margin-top: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 300px;
}

.contentContainerSlim {
  padding: 0 5px !important;
}

.actions {
  width: 100%;
  background-color: white;
}
