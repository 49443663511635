.placeholderFloater {
  position: absolute;
  height: 100%;
  padding: 12px 12px 12px 15px;
  line-height: 16px;
  color: #999;
  pointer-events: none;
}

.container {
  position: relative;
  display: block;
  width: 100%;
}
