.root {
  display: block;
  flex-grow: 1;
  padding: spacing(1.5);
  margin: 0;
  font-size: 1rem;
  color: inherit;
  background-color: transparent;
  border: 2px solid var(--black);

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.4);
  }
}

.wrapper {
  width: 100%;
}

.innerWrapper {
  display: flex;
  flex-direction: row;
}

.prefix {
  height: var(--click-target-height);
  padding: spacing(1);
  padding-right: spacing(4);
  margin: 0;
  font-size: inherit;
  background: white;
  border: 2px solid var(--black);
  border-right: none;
  border-radius: none;
}

.prefixWrapper {
  position: relative;
}

.chevron {
  &::before {
    position: absolute;
    top: 52%;
    right: 0;
    display: block;
    width: spacing(2.5);
    height: spacing(2.5);
    pointer-events: none;
    content: '';
    background-image: url('../../../icons/expand_more.svg');
    background-size: cover;
    transform: translate(-25%, -50%);
  }
}

.label {
  display: block;
  width: 100%;
  margin-bottom: spacing(1);
}

.error {
  border-color: var(--error-red);
}

.errorMsg {
  padding-top: 5px;
  font-size: 0.75rem;
  color: var(--error-red);
}
