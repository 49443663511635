.error {
  max-width: 520px;
  padding: 5px;
  margin: 0 auto;
  font-size: 0.75rem;
  color: var(--error-red) !important;
}

.modal {
  padding-bottom: spacing(6);
}

.button {
  margin-bottom: spacing(2);
}

.text {
  margin-bottom: spacing(10);
}

.marginTop {
  margin-top: spacing(2);
}

.marginBottom {
  margin-bottom: spacing(1);
}

.fullscreen {
  display: flex;
  flex-direction: column;
  padding: spacing(6);
  margin: auto;
  margin-top: spacing(6);
}
