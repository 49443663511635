/**
  NOTE:
    HOCs are Apex predators. The props they pass down will seldom be overridden.

    Therefore, to solve the composition problem surfacing when the HOCs css
    is put _after_ the components in the exported css and therefore overriden by defaults,
    the double `.class.class`is used to slightly increase specificity without using `!important`
*/

/** backgrounds: */
.bg-pink.bg-pink {
  background-color: var(--baby-pink);
}

.bg-offwhite.bg-offwhite {
  background-color: var(--offwhite);
}

.bg-white.bg-white {
  color: var(--black);
  background-color: var(--white);
}

.bg-denim.bg-denim {
  background-color: var(--denim);
}

.bg-green.bg-green {
  background-color: var(--soft-green);
}

.bg-black.bg-black {
  color: var(--white); /** Pass this along here since the fonts default are black*/
  background-color: var(--black);
}

.bg-lightgrey.bg-lightgrey {
  background-color: var(--grey-leia);
}

.bg-transparent.bg-transparent {
  background-color: transparent;
}

.bg-powderpink.bg-powderpink {
  background-color: var(--powder-pink);
}

.bg-error.bg-error {
  background-color: var(--error-red);
}

.bg-warning.bg-warning {
  background-color: var(--warning-yellow);
}

.dunesea-sand.dunesea-sand {
  background-color: var(--dunesea-sand);
}

.bg-verydark.bg-verydark {
  background-color: var(--verydark);
}

/** backgrounds: */
.bg-pink-responsive.bg-pink-responsive {
  @media ($phone-down) {
    background-color: var(--baby-pink);
  }
}

.bg-offwhite-responsive.bg-offwhite-responsive {
  @media ($phone-down) {
    background-color: var(--offwhite);
  }
}

.bg-white-responsive.bg-white-responsive {
  @media ($phone-down) {
    color: var(--black);
    background-color: var(--white);
  }
}

.bg-denim-responsive.bg-denim-responsive {
  @media ($phone-down) {
    background-color: var(--denim);
  }
}

.bg-green-responsive.bg-green-responsive {
  @media ($phone-down) {
    background-color: var(--soft-green);
  }
}

.bg-black-responsive.bg-black-responsive {
  @media ($phone-down) {
    color: var(--white); /** Pass this along here since the fonts default are black*/
    background-color: var(--black);
  }
}

.bg-lightgrey-responsive.bg-lightgrey-responsive {
  @media ($phone-down) {
    background-color: var(--grey-leia);
  }
}

.bg-transparent-responsive.bg-transparent-responsive {
  @media ($phone-down) {
    background-color: transparent;
  }
}

.bg-powderpink-responsive.bg-powderpink-responsive {
  @media ($phone-down) {
    background-color: var(--powder-pink);
  }
}

.bg-error-responsive.bg-error-responsive {
  @media ($phone-down) {
    background-color: var(--error-red);
  }
}

.bg-warning-responsive.bg-warning-responsive {
  @media ($phone-down) {
    background-color: var(--warning-yellow);
  }
}

/** colors: */

.inherit.inherit {
  color: inherit;
}

.pink.pink {
  color: var(--baby-pink);
}

.offwhite.offwhite {
  color: var(--offwhite);
}

.white.white {
  color: var(--white);
}

.denim.denim {
  color: var(--denim);
}

.green.green {
  color: var(--soft-green);
}

.black.black {
  color: var(--black);
}

.lightgrey.lightgrey {
  color: var(--grey-leia);
}

.error.error {
  color: var(--error-red);
}

.warning.warning {
  color: var(--warning-yellow);
}

.greysolo.greysolo {
  color: var(--grey-solo);
}

.verydark.verydark {
  color: var(--verydark);
}
