.modal {
  padding-bottom: spacing(6);
}

.fullscreen {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  padding: 0 spacing(2);
  margin: spacing(7) auto auto auto;
}

.actions {
  margin-top: spacing(10);
}

.grow {
  flex: 1;
}
