.root {
  position: relative;
  font-family: var(--fontFamily-sofia);
  font-weight: var(--bold);
  text-align: center;
  text-decoration: none;
  cursor: pointer;

  &[aria-disabled='true'] {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      opacity: 0.5;
    }
  }

  &:hover,
  &:focus {
    opacity: 0.9;
  }
}

body:global(.accessibility-mode) .root {
  &:focus {
    outline: 2px solid blue !important;
  }
}

.size-regular {
  padding: spacing(2);
  font-size: var(--baseFontSize);
  line-height: var(--baseFontSize);

  @media ($phone-up) {
    padding: spacing(2, 4);
  }
}

.size-slim {
  padding: spacing(2, 3);

  &.outlined {
    padding: calc(spacing(2) - 2px) spacing(3);
  }

  @media ($small-phone-down) {
    font-size: 0.8em; /* contextual font size here - this might be wrong */

    .icon {
      right: spacing(1);

      &.iconBefore {
        left: spacing(1);
      }
    }
  }
}

.singleicon {
  padding: spacing(2);
  color: var(--black);
  background: none;
  border-color: transparent;
  border-radius: 50%;
  transition: color 200ms ease;

  .icon {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    color: var(--grey-solo);
  }
}

.contained {
  color: var(--white);
  background-color: var(--black);
}

.text {
  padding: spacing(2, 6);
  color: var(--black);
  background: none;
  border-color: transparent;
}

.outlined {
  --borderSize: 2px;
  padding: calc(spacing(2) - var(--borderSize));
  color: var(--black);
  background-color: transparent;
  border: var(--borderSize) solid currentColor;

  @media ($phone-up) {
    padding: calc(spacing(2) - var(--borderSize)) calc(spacing(8) - var(--borderSize));
  }
}

.block {
  display: block;
  width: 100%;
}

.fullHeight {
  height: 100%;
}

.icon {
  position: absolute;
  top: 50%;
  right: spacing(2);
  left: auto;
  height: 100%;
  transform: translateY(-50%);

  &.iconBefore {
    left: spacing(2);
  }
}

.spinner {
  top: 50%;
  z-index: 200;
  display: inline-block;
  width: 25px;
  height: 25px;
  transform: translateY(-50%);
  animation: spinner 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes spinner {
  0% {
    transform: translateY(-50%) rotate(0deg);
  }

  100% {
    transform: translateY(-50%) rotate(360deg);
  }
}

.textStart {
  text-align: left;
}

.textEnd {
  text-align: right;
}

.buttonText {
  display: block;
}

.nowrap {
  overflow: hidden;
  color: var(--dark);
  text-overflow: ellipsis;
  white-space: nowrap;
}
