.root {
  display: flex;
  align-items: center;
  font-size: 1rem;
}

.label {
  padding-left: 1rem;

  &Before {
    padding-right: 1rem;
    padding-left: 0;
  }
}

.input {
  position: absolute;
  opacity: 0;
}

.fauxCheckbox {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;

  [aria-disabled='true'] ~ & {
    cursor: not-allowed;
    opacity: 0.6;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(1.25);
  }

  .empty {
    color: var(--grey-solo);

    :checked ~ & {
      display: none;
    }
  }

  .checked {
    display: none;
    color: var(--soft-green);

    :checked ~ & {
      display: block;
    }
  }

  .empty,
  .checked {
    :focus ~ & {
      color: var(--soft-green);
    }
  }
}
