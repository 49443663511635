.root {
  position: relative;
  display: block;
  padding: spacing(2) 0;

  & + &:not(.noPadding) {
    padding-top: spacing(7);
    padding-bottom: spacing(7);
  }

  & + &.noPaddingTop {
    padding-top: 0;
  }

  & + &.noPaddingBottom {
    padding-bottom: 0;
  }
}

.noPadding {
  padding: 0;
}

.extraTop {
  padding-top: spacing(4);
}

.extraBottom {
  padding-bottom: spacing(8);
}

.extra {
  padding: spacing(4) 0;
}

.around {
  padding: spacing(3, 2);
}

.bottomAligned {
  margin-top: auto;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.grid {
  display: grid;
}

.inline {
  display: inline-block;
}

.inlineBlock {
  display: inline-block;
}

.inlineFlex {
  display: inline-flex;
}
