.wrapper {
  text-align: right;
}

.root {
  margin-top: -1.25%; /** the height of the margin added into the Swoosh SVG */
  transform-origin: 50% 50%;

  &.mirrorY {
    margin-top: 0;
    margin-bottom: -1.25%;
  }
}

.mirrorX {
  transform: scaleX(-1);
}

.mirrorY {
  transform: scaleY(-1);

  &.mirrorX {
    transform: scale(-1, -1);
  }
}

.error {
  color: var(--error-red);
}

.warning {
  color: var(--warning-yellow);
}

.pink {
  color: var(--baby-pink);
}

.green {
  color: var(--soft-green);
}

.offwhite {
  color: var(--offwhite);
}

.white {
  color: var(--white);
}

.black {
  color: var(--black);
}

.lightgrey {
  color: var(--grey-leia);
}

.transparent {
  color: transparent;
}
