.root {
  width: 100%;
}

.spaced {
  & > * + * {
    margin-top: 15px;
  }
}

.paddingTop {
  padding-top: 15px;
}

.paddingBottom {
  padding-bottom: 15px;
}
