.root {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background-color: var(--white);
  border-radius: spacing(1);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
}

.padded {
  padding: spacing(2, 2);
}

.inner {
  position: relative;
  flex-grow: 1;
  transition: opacity 0.25s;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
