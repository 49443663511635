.root {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.5em;
}

.fauxInputWrapper {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  overflow: hidden;

  & > * + * {
    margin-left: 0.8rem;
  }
}

.input {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 68px;
  height: 90px;
  padding: 0;
  font-size: 48px;
  font-weight: var(--bold);
  color: var(--black);
  text-align: center;
  letter-spacing: 0;
  background: var(--grey-leia);
  border: 2px solid var(--grey-luke);
  border-radius: 3px;

  &:focus {
    border-color: var(--black);
    outline: none;
  }
}

.focused {
  border-color: var(--grey-solo);
}

.label {
  padding-bottom: spacing(3);
}

.error {
  color: var(--error-red);
  border-color: var(--error-red);
}

.success {
  color: var(--soft-green);
  border-color: var(--soft-green);
}
