.root {
  display: flex;
  align-items: center;
  font-size: 1rem;
  line-height: 1.5em;
}

.labelAfter {
  padding-left: 1rem;
  cursor: pointer;
}

.labelBefore {
  padding-right: 1rem;
  cursor: pointer;
}

.input {
  position: absolute;
  opacity: 0;
}

.fauxRadio {
  position: relative;
  display: inline-block;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  color: white;
  cursor: pointer;
  background-color: transparent;
  border: 2px solid var(--grey-solo);
  border-radius: 100%;

  &::after {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--soft-green);
    border-radius: 100%;
    transform: scale(0.6);
  }

  :checked ~ & {
    &::after {
      content: '';
    }
    border-color: var(--soft-green);
  }

  :focus ~ & {
    border-color: var(--soft-green);
  }
}
