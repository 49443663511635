.root {
  display: flex;
  flex-direction: column;

  > * + * {
    margin-top: spacing(2);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  width: 45px;
  height: 45px;
  color: var(--white);
  animation: spinner 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
