.inputWrapper {
  padding-bottom: spacing(5);
  margin-top: spacing(6);
}

.termsButtonWrapper {
  margin-top: spacing(3);
  text-align: center;
}

.bottom {
  padding-bottom: spacing(2);
  margin-top: auto;
}
