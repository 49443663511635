.root {
  position: relative;
  display: inline;
  padding: 0;
  font-family: var(--fontFamily-sofia);
  font-size: inherit;
  font-weight: var(--bold);
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;

  &[aria-disabled='true'] {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      opacity: 0.5;
    }
  }

  &:hover {
    text-decoration: underline;
  }

  &:visited {
    text-decoration: none;
  }
}

.smaller {
  font-size: 1rem;
}

.big {
  &::after {
    position: absolute;
    top: -50%;
    right: -25%;
    bottom: -50%;
    left: 0;
    content: '';
  }
}

.block {
  display: block;
  width: 100%;
}

.iconWrapper {
  position: relative;
  padding-right: 2em;
  transform: translateY(4px);

  &.iconAfter {
    padding-right: 1em;
    padding-left: 0.5em;
  }
}

.icon {
  position: absolute;
  top: 0;
  width: auto;
  height: 100%;
}
