.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  font-size: 1rem;

  legend {
    font-weight: var(--bold);
    line-height: 1.3em;
  }

  & > * + * {
    margin-top: 1rem;
  }

  & + & {
    margin-top: spacing(8);
  }

  &[aria-disabled='true'] {
    opacity: 0.6;
  }
}
