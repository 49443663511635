:root {
  /** colors: */
  --white: #fff;
  --black: #000;
  --verydark: #1d1d1d;
  --dark: #666;

  /** https://coolors.co/febcd9-5cc88b-41718a-faf0e6-ffbf69-ed1c24 */
  --baby-pink: #ffd4df;
  --baby-pink-dark: #ffb8c9;
  --pink-dark: #d14b6d;
  --denim: #41718a;
  --soft-green: #5cc88b;
  --deep-blue: #41718a;
  --textColor: #000;
  --error-red: #db6760;
  --warning-yellow: #ffbf69;
  --dunesea-sand: #fbe6bb;

  --offwhite: #faf0e6;
  --greyish: #f4f2f0;
  --lightgrey: #dedede;
  --subdued: #f0f0f0;
  --subdued-light: #fafafa;
  --powder-pink: #fbf4ed;
  --grey-leia: #f8f8f8;
  --grey-kenobi: #f2f2f2;
  --grey-luke: #e5e5e5;
  --grey-solo: #999999;
  --grey-chewie: #ededed;
  --grey-lando: #999;
  --grey-vader: #333;

  /** font-families : */
  --fontFamily-lemonde: le-monde-livre-std, serif;
  --fontFamily-sofia: sofia-pro, sans-serif;

  /** font-weights: */
  --bold: 700;
  --normal: 300;

  /** font-sizes: */
  --baseFontSize: 16px;

  /* misc: */
  --content-min-width: 298px;
  --content-max-width: 680px;
  --content-max-width-desktop: 750px;
  --content-max-width-desktop-medium: 950px;
  --content-max-width-desktop-large: 1440px;
  --navHeight: 68px;
  --footerHeight: 258px;
  --contextMenuHeight: 54px;
  --sideMargin: 20px;
  --sideMargin-desktop: 120px;
  --modalMargin: 15px;
  --modalMargin-desktop: 120px;
  --click-target-height: 48px;

  /* z-indexes: */
  /* Absolute top. */
  --z-top: 11000;

  /* overlays > 9k */
  --z-modal: 9999999999; /* make sure the tidio chat are below the modals */
  --z-menu: 9998;

  /* nav > 8k */
  --z-nav: 8999;
  --z-navMenu: 8998;
  --z-navColor: 8997;
  --z-navSwoosh: 8996;
  --z-navBackdrop: 8995;

  --z-above: 7000;
  font-family: var(--fontFamily-sofia);
  font-size: var(--baseFontSize);

  /** Breakpoints: */
  --phone-small-breakpoint: 320px;
  --phone-breakpoint: 600px;
  --tablet-landscape-breakpoint: 900px;
  --desktop-breakpoint: 1200px;
  --big-desktop-breakpoint: 1800px;

  /** Box Shadows: */
  --light-drop-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
  --strong-drop-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.24);

  ::selection {
    color: var(--white);
    background: var(--soft-green);
  }
}

* {
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeSpeed;
  font-size: 1.125rem;
  background: var(--offblack);
  scroll-behavior: smooth;
}

hr {
  margin: spacing(5) var(--sideMargin); /* use the page side margin so that you can use these outside of <Container/> */
  color: var(--grey-lando);
  opacity: 0.2;
}

button {
  color: inherit;
  cursor: pointer;
  background: none;
  border: none;

  &:focus {
    outline: none;
  }
}

.block {
  &-embed {
    margin-top: 1rem;
    margin-bottom: 1rem;

    iframe {
      max-width: 100%;
    }
  }

  &-media {
    margin-top: 1rem;
    margin-bottom: 1rem;

    video,
    audio {
      max-width: 100%;
    }
  }
}

input,
select {
  /* stylelint-disable-next-line */
  -webkit-border-radius: 0;
  /* stylelint-disable-next-line */
  -webkit-appearance: none;
}

@supports (-webkit-hyphens: none) {
  input {
    margin: 0;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* stylelint-disable-next-line */
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  /* stylelint-disable-next-line */
  -moz-appearance: textfield;
}

#djedi-cms {
  top: auto !important;
  bottom: 0 !important;
  z-index: 2147483647 !important;
}
